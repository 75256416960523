@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Lexend:wght@300;400;500;600;700;800;900&display=swap');


@font-face {
    font-family: 'BWGradual';
    src:
        url('../fonts/BwGradual-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

$primary-color: #660A15;
$primary-color-light: #FF98A4;
$primary-text-color: #593A3E;
$primary-text-color-light: #ebe6e6;


:root {
    --bs-body-font-family: 'Lexend', sans-serif;
    --bs-body-color: $primary-text-color;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
    background-color: $primary-color-light !important;
    color: $primary-text-color;
    border: none;
    font-family: 'Lexend', sans-serif;;
    font-size: 17px;
    font-weight: 500;
    padding: 17px;
    margin-top: 10px;
    margin-bottom: 51px;
}

.email-form {
    margin-top: 20px;
}  

nav {
    color: #333333;
    z-index: 2;
    position: relative;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    a {
        color: #333333;
        text-decoration: none;
        font-weight: bold;
    }

    ul {
        list-style: none;
        display: flex;
        gap: 4rem;
        bottom: 0;
    }
}



body {
    $display-font-sizes: (1: 6.854rem,
        2: 4.236rem,
        3: 2.618rem,
        4: 1.2rem,
    );



    $display-font-weight: 300;
    $display-line-height: $headings-line-height;
    margin-bottom: 0px;
}

.legal {
        margin-bottom: 40px;
        color: $primary-text-color;
        font-family: 'EB Garamond', serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.6;
}


.center-box {
    text-align: center;
}


.mega {
    color: $primary-text-color;
    font-family: "BWGradual", sans-serif;;
    font-size: 116.51px;
    font-weight: 900;
    line-height: 1.1;
    margin-bottom: 60px;
}


.h1 {
    color: $primary-text-color;
    font-family: "BWGradual", sans-serif;;
    font-size: 72.01px;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 40px;
}

.h2 {
    color: $primary-text-color;
    font-size: 44.5px;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 40px;
}

.h3 {
    color: $primary-text-color;
    font-size: 27.5px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 40px;
}

.h4 {
    color: $primary-text-color;
    font-size: 20.4px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 40px;
}

.body {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 40px;
}

.body2 {
    font-size: 14.11px;
    font-weight: 300;
    line-height: 1.75;
    margin-bottom: 40px;
}

.mb-3rem {
    margin-bottom: 3rem;
}

.temp-mt0 {
    margin-top: 0px;
}

.temp-mb0 {
    margin-bottom: 0px;
}

.link-text{
    cursor: pointer
}


.color-row {
    background-color: $primary-color;
    color: $primary-text-color-light;

    .mega {
        color: $primary-color-light;
    }

    .h1 {
        color: $primary-color-light;
    }

    .h2 {
        color: $primary-color-light;
    }

    .h3 {
        color: $primary-color-light;
    }

    .h4 {
        color: $primary-color-light;
    }


    margin-left: calc((100vw - 100%) / -2) !important;
    padding-left: calc((100vw - 100%) / 2);
    margin-right: calc((100vw - 100%) / -2) !important;
    padding-right: calc((100vw - 100%) / 2);

    padding-top: 5rem;
    padding-bottom: 5rem;

}

.plain-row {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.color-row-light {
    background-color: $primary-color-light;

    .mega {
        color: $primary-color ;
    }

    .h1 {
        color: $primary-color
    }

    .h2 {
        color: $primary-color
    }

    .h3 {
        color: $primary-color
    }

    .h4 {
        color: $primary-color
    }

    margin-left: calc((100vw - 100%) / -2) !important;
    padding-left: calc((100vw - 100%) / 2);
    margin-right: calc((100vw - 100%) / -2) !important;
    padding-right: calc((100vw - 100%) / 2);

    padding-top: 5rem;
    padding-bottom: 5rem;

}

.color-row-light-primary {
    background-color: $primary-text-color-light;

    .mega {
        color: $primary-color ;
    }

    .h1 {
        color: $primary-color
    }

    .h2 {
        color: $primary-color
    }

    .h3 {
        color: $primary-color
    }

    .h4 {
        color: $primary-color
    }

    margin-left: calc((100vw - 100%) / -2) !important;
    padding-left: calc((100vw - 100%) / 2);
    margin-right: calc((100vw - 100%) / -2) !important;
    padding-right: calc((100vw - 100%) / 2);

    padding-top: 5rem;
    padding-bottom: 5rem;

}

@media (max-width: 767px) {

    .header-right{
        text-align: right;
        line-height: 1.2;
    }

    .mega {
        font-size: 51.88px;
        margin-bottom: 40px;
    }

    .h1 {
        font-size: 41.50px;
        margin-bottom: 30px;
    }
    
    .h2 {
        font-size: 33.20px;
        margin-bottom: 20px;
    }
    
    .h3 {
        font-size: 26.56px;
        margin-bottom: 20px;
    }
    
    .h4 {
        font-size: 21.25px;
        margin-bottom: 20px;
    }
    
    .body {
        font-size: 17px;
        margin-bottom: 20px;
    }
    
    .body2 {
        font-size: 14.11px;
        margin-bottom: 20px;
    }
  }